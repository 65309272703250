import PropTypes from "prop-types";
import React from "react";

import S from "./review-card.style";

const ReviewCard = ({ name, location, children }) => (
    <S>
        <S.title>
            {name} <S.location>from {location}</S.location>
        </S.title>
        <S.decor>
            <S.decorLetter>”</S.decorLetter>
        </S.decor>
        <S.content>{children}</S.content>
    </S>
);

ReviewCard.propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.string,
    name: PropTypes.string,
};

export default ReviewCard;
