import styled, { Box } from "@xstyled/styled-components";

const S = styled(Box)`
    position: relative;
    border-radius: 8;
    padding: 4;
    box-shadow: regular;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

S.title = styled.h2`
    font-size: 4;
    font-weight: bold;
    padding-right: 5;
    margin-bottom: 3;
    font-family: "Josefin Sans", sans-serif;
`;

S.location = styled.span`
    color: primary;
    font-size: 3;
    font-family: "Nunito", sans-serif;
`;

S.content = styled.div`
    font-size: 3;
`;

S.decor = styled.div`
    right: 28px;
    top: -110px;
    position: absolute;
    font-size: 150px;
    min-width: 50px;
    opacity: 0.2;
    pointer-events: none;
`;

S.decorLetter = styled.div`
    position: absolute;
    top: calc(50% + 85px);
`;

export default S;
