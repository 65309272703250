import { Box, useUp } from "@xstyled/styled-components";
import React, { useCallback, useEffect, useState } from "react";

import TripAdvisorReviewsNarrow from "./tripadvisor-reviews.narrow";
import TripAdvisorReviewsWide from "./tripadvisor-reviews.wide";

const TripAdvisorReviews = () => {
    const upSm = useUp("sm");

    const [loadingWide, setLoadingWide] = useState(false);
    const [loadingNarrow, setLoadingNarrow] = useState(false);

    const handleReadyWide = useCallback(() => {
        setLoadingWide(true);
    }, [setLoadingWide]);

    const handleReadyNarrow = useCallback(() => {
        setLoadingNarrow(true);
    }, [setLoadingNarrow]);

    useEffect(() => {
        if (loadingWide && loadingNarrow) {
            /* eslint-env browser */
            window.taValidate && window.taValidate();
        }
    }, [loadingWide, loadingNarrow]);

    return (
        <>
            <Box style={{ display: upSm ? "block" : "none" }}>
                <TripAdvisorReviewsWide onReady={handleReadyWide} />
            </Box>
            <Box style={{ display: upSm ? "none" : "block" }}>
                <TripAdvisorReviewsNarrow onReady={handleReadyNarrow} />
            </Box>
        </>
    );
};

export default TripAdvisorReviews;
