import { Box } from "@xstyled/styled-components";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import useScript from "react-script-hook";

import { removeScriptBySrc } from "../../../../utils";

const TripadvisorReviewsWide = ({ onReady }) => {
    const htmlRef = useRef();
    const scriptSrc =
        "https://www.tripadvisor.com/WidgetEmbed-selfserveprop?uniq=607&border=true&lang=en_US&locationId=21182183&rating=true&nreviews=5&writereviewlink=true&popIdx=true&iswide=true&border=true&display_version=2";

    const [loading] = useScript({
        src: scriptSrc,
        onload: function () {
            this.loadtrk = true;
        },
        checkForExisting: true,
    });

    useEffect(() => {
        if (!loading && htmlRef) {
            onReady();
        }
    }, [htmlRef, loading, onReady]);

    useEffect(() => {
        return () => {
            removeScriptBySrc(scriptSrc);
        };
    }, []);

    return (
        <Box
            className="TA_selfserveprop"
            id="TA_selfserveprop607"
            maxWidth={468}
            minWidth={468}
            overflow="hidden"
            position="relative"
            ref={htmlRef}
            width={468}
        >
            <ul id="m1oY9g" className="TA_links owKB6d6s">
                <li
                    id="R3YC9XNamN"
                    className="vMpz972cb0"
                    style={{ listStyle: "none" }}
                >
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.tripadvisor.fr/Attraction_Review-g187147-d21182183-Reviews-Paris_Insight-Paris_Ile_de_France.html"
                    >
                        <img
                            width="0"
                            src="https://www.tripadvisor.fr/img/cdsi/img2/branding/v2/Tripadvisor_lockup_horizontal_secondary_registered-11900-2.svg"
                            alt="TripAdvisor"
                        />
                    </a>
                </li>
            </ul>
        </Box>
    );
};

TripadvisorReviewsWide.propTypes = {
    onReady: PropTypes.func,
};

export default TripadvisorReviewsWide;
