import { Box } from "@xstyled/styled-components";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import BestReviewCard from "../components/common/BestReviewCard/best-review-card";
import PictureBox from "../components/common/PictureBox/picture-box";
import SectionSeparator from "../components/common/SectionSeparator/section-separator";
import Container from "../components/layout/Container/container";
import ContainerNarrow from "../components/layout/ContainerNarrow/container-narrow";
import Layout from "../components/layout/Main/main";
import ReviewCard from "../components/pages/reviews/ReviewCard/review-card";
import TripadvisorReviews from "../components/pages/reviews/TripadvisorReviews/tripadvisor-reviews";
import SEO from "../components/seo";
import Headline from "../components/typography/Headline/headline";

const Reviews = ({ data }) => (
    <Layout>
        <SEO
            title="Reviews"
            description="You are welcome to share your thoughts. Virginia appreciates authenticity, not cliché."
            image={data.eiffelTower.publicURL}
        />
        <Container>
            <Box my={5}>
                <Headline textAlign="center">Reviews</Headline>
            </Box>
            <ContainerNarrow pb={4}>
                <BestReviewCard />
            </ContainerNarrow>
            <SectionSeparator />
            <Headline textAlign="center" as="h2" variant="h2" my={5}>
                More reviews
            </Headline>
            <Box px={0}>
                <Box row mx={{ xs: 0, md: -3 }} mb={5} alignContent="strech">
                    <Box
                        col={{ xs: 1, lg: 2 / 3 }}
                        mb={4}
                        px={{ xs: 0, md: 3 }}
                    >
                        <ReviewCard name="Paul Montague" location="Sydney">
                            Had I not taken Virginia’s “A Moveable Feast”
                            walking tour on my first visit to Paris, 10 years
                            ago, I doubt whether I would have ever returned. I
                            had enjoyed my stay, but at the same time I felt
                            something was lacking. I felt I was enjoying only
                            postcard views of the city, I wanted something
                            deeper! I found that “something deeper” on my second
                            last day during Virginia’s walk. There were four of
                            us. Starting at Les Deux Magots, we walked and
                            talked our way through the Left Bank, Virginia
                            bringing the Hemingway period and characters to life
                            with her stories. Her ability to access private
                            courtyards and gardens, showing us hidden history
                            and beauty was fascinating. It was an emboldening
                            and life changing experience for me. Since then, I
                            have returned to Paris each year, I just love
                            walking the city, for me, it is not the icons that
                            make Paris, it is the small things, off the tourist
                            path, and Virginia’s knowledge of these is endless.
                            In my 10 years of visits, I have enjoyed and learnt
                            so much from her walking tours, such as, the Marais,
                            Montmartre and the unforgettable Pere Lachaise.
                        </ReviewCard>
                    </Box>
                    <Box
                        col={{ xs: 1, sm: 1 / 2, lg: 1 / 3 }}
                        mb={4}
                        px={{ xs: 0, md: 3 }}
                        minHeight={350}
                    >
                        <Box position="relative" width="100%" height="100%">
                            <PictureBox
                                rounded
                                fluid={data.hiddenGarden.childImageSharp.fluid}
                            />
                        </Box>
                    </Box>
                    <Box
                        col={{ xs: 1, md: 1 / 2 }}
                        mb={4}
                        px={{ xs: 0, md: 3 }}
                    >
                        <ReviewCard name="Patty Hagar" location="Texas">
                            Not only did I get to know Paris, I got to know
                            you...which made the whole experience more important
                            than I possibly imagined. It isn&apos;t often that
                            you go on a vacation and feel like you are finally
                            at home. With such limited time and space in
                            ones&apos; life it is necessary to keep the
                            souvenirs which are truly meaningful. For me this
                            weekend, the long walk, the conversations I had with
                            Virginia and the Paris behind the scenes she knows
                            so well are a lifetime memory.
                        </ReviewCard>
                    </Box>
                    <Box
                        col={{ xs: 1, md: 1 / 2 }}
                        mb={4}
                        px={{ xs: 0, md: 3 }}
                    >
                        <ReviewCard name="Joyce Baker" location="Detroit">
                            I have been to Paris several times but always felt a
                            stranger to the city. So imagine my delight to
                            discover Paris Insight. Virginia helped me
                            understand the city and its inhabitants. Her love
                            for and knowledge of Paris are contagious and make
                            you want to return over and over again. No tour book
                            or travel agency tours can give you what she does.
                            Thank you for encouraging me to get to know Paris
                            better. I will be back.
                        </ReviewCard>
                    </Box>
                    <Box
                        col={{ xs: 1, sm: 1 / 2, lg: 1 / 3 }}
                        mb={4}
                        px={{ xs: 0, md: 3 }}
                        minHeight={350}
                    >
                        <Box position="relative" width="100%" height="100%">
                            <PictureBox
                                rounded
                                fluid={data.eiffelTower.childImageSharp.fluid}
                            />
                        </Box>
                    </Box>
                    <Box
                        col={{ xs: 1, lg: 2 / 3 }}
                        mb={4}
                        px={{ xs: 0, md: 3 }}
                    >
                        <ReviewCard
                            name="Anne &amp; Peter Whaite"
                            location="Norfolk"
                        >
                            On 6th October you took my husband, Peter and me on
                            a wonderful tour of Le Marais. You opened our eyes
                            to the Paris we knew was there but which is rarely
                            discovered by the casual tourist. I can&apos;t tell
                            you how much we enjoyed every moment with you. You
                            will be delighted to know that we are both now
                            reading “Les Miserables”. I think it will probably
                            take us some time !
                        </ReviewCard>
                    </Box>
                </Box>
            </Box>
            <SectionSeparator />
            <Headline textAlign="center" as="h2" variant="h2" my={5}>
                Tripadvisor reviews
            </Headline>
            <Box row mb={5} pb={3} justifyContent="center">
                <TripadvisorReviews />
            </Box>
        </Container>
    </Layout>
);

Reviews.propTypes = {
    data: PropTypes.object,
};

export const pageQuery = graphql`
    query ReviewsPageQuery {
        hiddenGarden: file(
            relativePath: { eq: "reviews/hidden-garden.jpg" }
            sourceInstanceName: { eq: "images" }
        ) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        eiffelTower: file(
            relativePath: { eq: "reviews/eiffel-tower-sunset.jpg" }
            sourceInstanceName: { eq: "images" }
        ) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;

export default Reviews;
